<template>
  <CRow class="align-items-center">
    <CCol>
      <CProgress>
        <CProgressBar
          v-for="progress in progressStatus()"
          :key="progress.id"
          :color="`orders-${progress.color}`"
          :value="progress.percentage"
          >{{ progress.value }}</CProgressBar
        >
      </CProgress>
    </CCol>
  </CRow>
</template>

<script>
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { processProgressData } from "../../../../../../helpers/common";
import { roundListProgressMap } from "../../../../../../config/global";

export default {
  name: "OrderRoundOrders",

  data() {
    return {
      data: {},
    };
  },

  methods: {
    progressStatus() {
      const statusMap = get(this.data, "tot_status", {});
      const itemCount = get(this.data, "count_prods", 0);
      return processProgressData(
        itemCount,
        statusMap,
        cloneDeep(roundListProgressMap)
      );
    },
  },
};
</script>
